import '@babel/polyfill';
import '../scss/manifest.scss';
import Swiper from 'swiper/dist/js/swiper';
import navigation from './navigation';

navigation.init();

// swiper init
// eslint-disable-next-line
const mySwiper = new Swiper('.swiper-container', {
  loop: true,
  simulateTouch: false,
  autoplay: {
    delay: 100000000,
  },
  pagination: {
    el: '.slider__pagination',
    type: 'bullets',
  },
  navigation: {
    nextEl: '.slider__next',
    prevEl: '.slider__prev',
  },
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },
});

function setVisibilityByClassname(classname, hide) {
  Array.from(document.getElementsByClassName(classname)).forEach((elem) => {
    if (hide) {
      elem.classList.add('event-block--hidden');
    } else {
      elem.classList.remove('event-block--hidden');
    }
  });
}

function setButtonSelected(elem) {
  // eslint-disable-next-line
  var sibling = elem.parentNode.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      sibling.classList.remove('event-list__filter__button--selected');
    }
    sibling = sibling.nextSibling;
  }

  elem.classList.add('event-list__filter__button--selected');
}

const liveSearchRequest = new XMLHttpRequest();
liveSearchRequest.onload = () => {
  const searchResults = document.getElementById('js-search__results');
  if (liveSearchRequest.status >= 200 && liveSearchRequest.status < 300) {
    searchResults.classList.remove('search__input--hidden');
    const results = JSON.parse(liveSearchRequest.responseText);
    let resultHtml = '<ul>';
    Array.from(results.results).forEach((result) => {
      resultHtml += `<li><a href="${result.url}"><h3>${result.title}</h3> <p>${
        result.desc
      }</p> </a></li>`;
    });
    resultHtml += '</ul><a class="js-search__close search__close"></a>';
    searchResults.innerHTML = resultHtml;
  } else {
    // search request failed
  }
};

function addMultipleEventListener(element, events, handler) {
  events.forEach((e) => element.addEventListener(e, handler)); // eslint-disable-line arrow-parens
}

function matches(event, key) {
  // IE compat
  return event.target.matches
    ? event.target.matches(key)
    : event.target.msMatchesSelector(key);
}

// buttons on event archive page
addMultipleEventListener(document, ['click', 'touchend'], (event) => {
  // If the clicked element doesn't have the right selector, bail
  if (matches(event, '.js-event-list__filter-all')) {
    event.preventDefault();
    setButtonSelected(event.target);
    setVisibilityByClassname('js-event-block--specialist', false);
    setVisibilityByClassname('js-event-block--patients', false);
  } else if (matches(event, '.js-event-list__filter-patients')) {
    event.preventDefault();
    setButtonSelected(event.target);
    setVisibilityByClassname('js-event-block--specialist', true);
    setVisibilityByClassname('js-event-block--patients', false);
  } else if (matches(event, '.js-event-list__filter-specialists')) {
    event.preventDefault();
    setButtonSelected(event.target);
    setVisibilityByClassname('js-event-block--specialist', false);
    setVisibilityByClassname('js-event-block--patients', true);
  } else if (event.target.classList.contains('js-search__label')) {
    Array.from(
      document.getElementsByClassName('js-search-and-lang__item'),
    ).forEach((elem) => {
      elem.classList.add('header__search-and-lang__item--hidden');
    });
    const searchInput = document.getElementById('search__input');
    searchInput.value = '';
    searchInput.classList.remove('search__input--hidden');
    searchInput.focus();
    searchInput.onkeyup = () => {
      const input = searchInput.value;
      if (input.length < 3) {
        return;
      }
      if ('veranstaltungen'.startsWith(input.toLowerCase())) {
        const searchResults = document.getElementById('js-search__results');
        searchResults.classList.remove('search__input--hidden');
        searchResults.innerHTML = `<ul>
  <li>
    <a href="/veranstaltungen/">
      <h3>Veranstaltungen</h3>
    </a>
  </li>
</ul>
<a class="js-search__close search__close"></a>`;
        return;
      }
      liveSearchRequest.open(
        'GET',
        `${searchInput.getAttribute('data-target')}?q=${input}`,
      );
      liveSearchRequest.send();
    };
  } else if (event.target.classList.contains('js-search__close')) {
    const searchInput = document.getElementById('search__input');
    Array.from(
      document.getElementsByClassName('js-search-and-lang__item'),
    ).forEach((elem) => {
      elem.classList.remove('header__search-and-lang__item--hidden');
    });
    searchInput.classList.add('search__input--hidden');
    document
      .getElementById('js-search__results')
      .classList.add('search__input--hidden');
  }
});
